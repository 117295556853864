import moment from "moment"
import { CALENDAR_URL, DESTINATIONS_URL, PHOTO_TOURS_URL } from "./constants"
import React from "react"

const getTourPrice = tour => {
  let price = 0
  let balance = 0
  let deposit = 0
  let currency = ""

  tour.sessions
    .filter(session => {
      return moment(session.start) > moment()
    })
    .sort((a, b) => {
      return new Date(b.start) - new Date(a.start)
    })
    .map(session => {
      if (session.price < price || price === 0) {
        price = session.price
        balance = session.balance
        deposit = session.deposit
        currency = session.currency
      }
      return session
    })
  switch (currency) {
    case "EUR":
      currency = "€"
      break
    case "USD":
      currency = "$"
      break
    case "GBP":
      currency = "£"
      break
    default:
      currency = "€"
      break
  }
  return {
    price: price,
    balance: balance,
    deposit: deposit,
    currency: currency,
  }
}

const getTourCoaches = tour => {
  let coaches = []
  getOrderedTourSessions(tour).map(session => {
    return session.users.map(u => {
      let exists = false
      for (let i = 0; i !== coaches.length; i++) {
        let coach = coaches[i]
        if (coach.id === u.id) {
          exists = true
          break
        }
      }
      if (exists === false) {
        coaches.push(u)
      }
      return u
    })
  })
  return coaches
}

const getTourCurrency = currency => {
  switch (currency) {
    case "EUR":
      return "€"
    case "USD":
      return "$"
    case "GBP":
      return "£"
    default:
      return "€"
  }
}

const getOrderedTourSessions = tour => {
  return tour.sessions
    .filter(session => {
      return moment(session.start) > moment()
    })
    .sort((a, b) => {
      return new Date(a.start) - new Date(b.start)
    })
}

const getOrderedSessions = sessions => {
  return sessions
    .filter(session => {
      return moment(session.start) > moment()
    })
    .sort((a, b) => {
      return new Date(a.start) - new Date(b.start)
    })
}

const getTourNextDeparture = tour => {
  const sessions = getOrderedTourSessions(tour)

  for (let i = 0; i !== sessions.length; i++) {
    const session = sessions[i]

    if (moment(session.start) > moment()) {
      return moment(session.start).locale("it").format("D MMM YYYY")
    }
  }
}

const getLocationPictures = location => {
  const picturesArray = []
  location.pictures?.map(p => {
    if (p.image[0] !== undefined) {
      picturesArray.push({
        key: p.title,
        localFile: {
          childImageSharp: {
            ...p.image[0].localFile.childImageSharp,
            thumbAlt: p.title,
            title: p.title,
          },
        },
      })
    }
    return p
  })
  return picturesArray
}
const getGalleryFromUsers = tour => {
  const picturesArray = []
  tour.pictures?.map(p => {
    if (p.type === "user_generated" || p.type === "group") {
      if (p.image[0] !== undefined) {
        picturesArray.push({
          key: p.title,
          localFile: {
            childImageSharp: {
              ...p.image[0].localFile.childImageSharp,
              thumbAlt: p.title,
              title: p.title,
            },
          },
        })
      }
    }
    return p
  })
  return picturesArray
}
const getLocalizedLinkToMonth = (l, year, month) => {
  return month
    ? `/${PHOTO_TOURS_URL[l.language]}/${
        CALENDAR_URL[l.language]
      }/${year}/${month}/`
    : `/${PHOTO_TOURS_URL[l.language]}/${CALENDAR_URL[l.language]}/${year}/`
}
const getCalendar = (tours, year, month) => {
  let months = []
  var finalMonths = []

  for (let i = 0; i !== tours.length; i++) {
    const tour = tours[i].node ? tours[i].node : tours[i]
    for (let j = 0; j !== tour.sessions.length; j++) {
      let session = tour.sessions[j]
      if (
        (month === undefined && year === undefined) ||
        (month === undefined &&
          year === moment(session.start).format("YYYY")) ||
        (year === moment(session.start).format("YYYY") &&
          month === moment(session.start).format("MMMM"))
      ) {
        if (moment(session.start).format("X") > moment().format("X")) {
          let start = `${moment(session.start).format("MM")}/${moment(
            session.start
          ).format("YYYY")}`
          if (months[start]) {
            months[start].tours.push({ session: session, tour: tour })
          } else {
            months[start] = {
              first: moment(session.start).format("X"),
              month: moment(session.start).format("MMMM"),
              year: moment(session.start).format("YYYY"),
              tours: [],
            }
            months[start].tours.push({ session: session, tour: tour })
          }
        }
      }
    }
  }

  Object.keys(months).map((key, i) => {
    var m = months[key]

    if (m["year"] >= moment().format("YYYY")) {
      finalMonths.push(m)
    }
    return m
  })

  var obj = [...finalMonths]
  obj.sort((a, b) => a.first - b.first)

  return obj
}

const getTourPictures = tour => {
  const picturesArray = []
  tour.pictures?.map(p => {
    if (p.type === "tour") {
      Object.keys(p.image).map((key, i) => {
        let image = p.image[key]
        picturesArray.push({
          localFile: {
            childImageSharp: {
              ...image.localFile.childImageSharp,
              thumbAlt: p.title,
              title: p.title,
            },
          },
        })
      })
    }
    return p
  })
  return picturesArray
}

const findNextDay = (days, index, number) => {
  for (let i = index + 1; i !== days.length; i++) {
    let nextDay = days[i]
    let day = days[i - 1]

    if (day.title === nextDay.title) {
      if (number > 0) {
        number = number + "," + nextDay.number
      } else {
        if (number.includes(nextDay.number) === false) {
          number = number + "," + nextDay.number
          return findNextDay(days, index + 1, number)
        }
      }
      return findNextDay(days, index + 1, number)
    } else {
      return number
    }
  }
}

const getCondensedDays = tour => {
  return tour.days
    ?.map((day, index) => {
      if (tour.days[index + 1]) {
        let nextDay = tour.days[index + 1]
        if (day.title === nextDay.title) {
          let num = findNextDay(tour.days, index, day.number)
          day.number = num
        }
      }
      return day
    })
    .filter((day, index) => {
      if (tour.days[index - 1]) {
        let nextDay = tour.days[index - 1]
        if (day.title === nextDay.title) {
          return false
        }
      }
      return day
    })
}
const removeDuplicates = tours => {
  var cleanTours = []
  tours.map((t, index) => {
    if (index === 0) {
      cleanTours.push(t)
    } else {
      if (!tourExistsIn(t, cleanTours)) {
        cleanTours.push(t)
      }
    }
    return t
  })
  return cleanTours
}

const tourExistsIn = (tour, tours) => {
  var exists = false
  var i = 0

  while (!exists && i < tours.length) {
    let t = tours[i]

    if (t.node && t.node.id) {
      if (t.node.id === tour.node.id) {
        exists = true
      }
    } else {
      if (tour.id === t.id) {
        exists = true
      }
      if (tour.tour_id === t.tour_id) {
        exists = true
      }
    }
    i++
  }
  return exists
}

const getProductsList = (tours, list) => {
  const arrayProducts = []
  for (let i = 0; i !== tours.length; i++) {
    const tour = tours[i].node ? tours[i].node : tours[i]

    arrayProducts.push({
      name: tour.title,
      id: tour.strapiId,
      price: tour.sessions[0].price,
      brand: "WeShoot",
      category: "Viaggi Fotografici",
      list: list,
      position: i,
    })
  }
  return arrayProducts
}

const getDayPreviews = day => {
  let pictures = []
  day.steps.map(step => {
    step.locations.map(location => {
      location.pictures.map(picture => {
        if (picture.type === "tour") {
          for (let i = 0; i !== picture.image.length; i++) {
            let p = picture.image[i]
            pictures.push({
              localFile: {
                childImageSharp: {
                  ...p.localFile.childImageSharp,
                  thumbAlt: p.name,
                  title: location.title,
                  caption: location.description,
                },
              },
            })
          }
        }
        return picture
      })
      return location
    })
    return step
  })
  return pictures
}

const getTourDays = (end, start) => {
  const diffTime = Math.abs(new Date(end).getTime() - new Date(start).getTime())
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1
}
const getTourBreadCrumbs = (tour, t, l) => {
  return [
    {
      name: "WeShoot",
      path: "/",
    },
    {
      name: "Viaggi Fotografici",
      path: `/${PHOTO_TOURS_URL[l.language]}/`,
    },
    {
      name: "Destinazioni",
      path: `/${PHOTO_TOURS_URL[l.language]}/${DESTINATIONS_URL[l.language]}/`,
    },
    {
      name: tour.states.name,
      path: `/${PHOTO_TOURS_URL[l.language]}/${DESTINATIONS_URL[l.language]}/${
        tour.states.slug
      }/`,
    },
    {
      name: tour.slug,
      path: `/${PHOTO_TOURS_URL[l.language]}/${DESTINATIONS_URL[l.language]}/${
        tour.states.slug
      }/${tour.places.slug}/${tour.slug}`,
    },
  ]
}

const getTourLink = (tour, l) => {
  return `/${PHOTO_TOURS_URL[l.language]}/${DESTINATIONS_URL[l.language]}/${
    tour.states.slug
  }/${tour.places.slug}/${tour.slug}`
}

const createMarkup = string => {
  return { __html: string }
}

export {
  getTourPrice,
  tourExistsIn,
  removeDuplicates,
  getProductsList,
  getLocationPictures,
  getTourLink,
  getCalendar,
  getTourCurrency,
  getOrderedSessions,
  getLocalizedLinkToMonth,
  getTourBreadCrumbs,
  getTourCoaches,
  getDayPreviews,
  getTourPictures,
  createMarkup,
  getGalleryFromUsers,
  getTourNextDeparture,
  getCondensedDays,
  getTourDays,
}
